require( 'owl.carousel' );

(
	function ( $ ) {
		$( document ).ready( function ( $ ) {
			// Menu toggle
			$( '#menu-toggle' ).on( 'click', function ( e ) {
				e.preventDefault();
				$( this ).toggleClass( 'open' );
				$( '#mobile-menu-expanded' ).toggleClass( 'open' );
			} );

			// Menu Scroll
			$( '.desktop-menu nav>ul>li>a' ).on( 'click', function ( e ) {
				const href = $( this ).attr( 'href' );
				if ( href.startsWith( '#' ) ) {
					e.preventDefault();
					$( 'html,body' ).animate( {
						scrollTop: $( href ).offset().top
					}, 1000 );
				}
			} );

			$( 'mobile-menu nav>ul>li>a' ).on( 'click', function ( e ) {
				$( '#menu-toggle' ).trigger( 'click' );
				const href = $( this ).attr( 'href' );
				if ( href.startsWith( '#' ) ) {
					e.preventDefault();
					$( 'html,body' ).animate( {
						scrollTop: $( href ).offset().top
					}, 1000 );
				}
			} );

			// Tickets scroll
			$( '.mobile-menu .tickets-wrap>.btn' ).on( 'click', function ( e ) {
				e.preventDefault();
				const href = $( this ).attr( 'href' );
				$( 'html,body' ).animate( {
					scrollTop: $( href ).offset().top
				}, 1000 );
			} );

			// Carousel
			$( '.owl-carousel' ).owlCarousel( {
				items: 1,
				loop: true,
				nav: false,
				dots: false,
				autoplay: true,
				autoplayTimeout: 5000,
				smartSpeed: 1000
			} );

			// Collapsing sections
			$( '.collapsing-section>h3>a' ).on( 'click', function ( e ) {
				e.preventDefault();
				$( this ).parents( '.collapsing-section' ).toggleClass( 'open' ).find( '.collapsing-content' ).slideToggle();
			} );

			// Zip Search
			$( '#zip_search_form' ).on( 'submit', function ( e ) {
				e.preventDefault();

				var zip_search = $( '#zip_search' ).val();

				if ( zip_search ) {
					$.ajax( {
						url: wp.ajax_url,
						method: 'POST',
						data: {
							action: 'get_zip_events',
							zipcode: zip_search
						},
						dataType: 'html',
						error: function () {
							alert( 'An error has occurred fetching events!' );
						},
						success: function ( data ) {
							$( '#zip_events' ).html( data );
						}
					} );
				}
			} );

			// Tickets view more
			if ( $( '.venue-group' ).length <= 5 ) {
				$( '#events .more' ).hide();
			} else {
				$( '.venue-group:nth-child(n+5)' ).hide();
			}

			$( '#events .more>a' ).on( 'click', function ( e ) {
				e.preventDefault();
				$( '.venue-group:nth-child(n+5)' ).fadeToggle();
			} );
		} );
	}
)( jQuery );